<template>
  <v-select
    :items="budgetCodesWithLocation"
    label="Budget Code"
    v-model="value"
    v-bind="inputProps"
    return-object
    :loading="loading"
    clearable
    :disabled="$attrs.disabled || disabled"
    :persistent-hint="disabled"
    :hint="getHint"
  />
</template>

<script>
import { inputProps } from '@/util';
import { mapGetters, mapActions } from 'vuex';
import { fundingSourceTypesMap } from '@/util/constants';

export default {
  data() {
    return {
      inputProps: this.useInputProps ? inputProps : {},
      loading: false,
      disabled: false,
    };
  },
  watch: {
    value(val) {
      this.$emit('input', this.returnObject ? val : val?.text);
    },
  },
  props: {
    locationId: { type: Number, default: 0 },
    fundingSource: { type: Object, default: () => ({}) },
    useInputProps: { type: Boolean, default: true },
    returnObject: { type: Boolean, default: true },
    adminOnly: { type: Boolean, default: true },
    invoicePayment: { type: Object, default: () => ({}) },
    value: { tyoe: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters('config', ['budgetCodeConfig']),
    ...mapGetters('budgetCode', ['budgetCodes']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('user', ['isAdmin', 'me']),
    ...mapGetters('tripRequest', ['canApproveCurrentTrip']),
    ...mapGetters('fundingSource', ['fundingSourcesById']),
    getHint() {
      if (!this.disabled) return undefined;

      if (this.adminOnly && !this.canApproveCurrentTrip)
        return 'You must be able to approve the current trip to select a budget code.';

      if (this.adminOnly && !this.isAdmin) return 'You must be an admin to select a budget code.';

      return undefined;
    },
    locationCode() {
      return this.locationsById[this.locationId]?.code || '';
    },
    budgetCodesWithLocation() {
      const fundingSource = this.fundingSourcesById[this.fsID];
      const type = fundingSource?.type;
      const index = this.budgetCodeConfig.findIndex((config) => config.locationDependent === true);

      let budgetCodes = [];
      if (type === fundingSourceTypesMap.singleBudgetCode) {
        budgetCodes = this.budgetCodes.filter((bc) => Object.values(bc.code).join('').includes('?'));
      } else if (type === fundingSourceTypesMap.singleBudgetCodeAllotments) {
        budgetCodes = this.budgetCodes.filter((bc) => !Object.values(bc.code).join('').includes('?'));
      } else if (type === fundingSourceTypesMap.multipleBudgetCodes) {
        if (index !== -1) {
          budgetCodes = this.budgetCodes.filter((bc) => {
            const codes = Object.values(bc.code);
            const codesString = codes.join(' ');
            const hasWildCard = codesString.includes('?');

            if (!codes[index]) return false;
            if (hasWildCard) return false;

            return codes[index]?.toString() === this.locationCode?.toString();
          });
        }
      }

      return budgetCodes.map((bc) => {
        return {
          value: bc.id,
          text: this.getDescription({
            budgetCode: bc,
            locationCode: this.locationCode,
            config: this.budgetCodeConfig,
          }),
        };
      });
    },
    fsID() {
      return this.fundingSource.id || this.fundingSource.fundingSourceId;
    },
  },
  methods: {
    ...mapActions('budgetCode', ['getBudgetCodes']),
    getDescription({ budgetCode, locationCode, config }) {
      const index = config.findIndex((c) => c.locationDependent === true);
      const budgetCodeValues = Object.values(budgetCode.code);

      if (index !== -1 && budgetCodeValues[index]?.includes('?')) budgetCodeValues[index] = locationCode;

      if (budgetCodeValues?.length > 0) return budgetCodeValues.join(' ');
      else return budgetCode.name;
    },
    replaceWithLocationCode(budgetCode) {
      return budgetCode.replace(/\?+/g, this.locationCode);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getBudgetCodes();

      if (this.me.is.superAdmin) return;

      if (this.adminOnly && !this.isAdmin) this.disabled = true;
      if (this.adminOnly && !this.canApproveCurrentTrip) this.disabled = true;
    } catch (e) {
      this.$myalert.error('An unexpected error occurred while processing your request. Please try again later.', true);
    } finally {
      this.loading = false;
    }
  },
};
</script>
