var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        attrs: {
          items: _vm.budgetCodesWithLocation,
          label: "Budget Code",
          "return-object": "",
          loading: _vm.loading,
          clearable: "",
          disabled: _vm.$attrs.disabled || _vm.disabled,
          "persistent-hint": _vm.disabled,
          hint: _vm.getHint,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      },
      "v-select",
      _vm.inputProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }